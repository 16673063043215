<template>
<div class="container-fluid mt-5 pt-5">
    <BasePageBreadCrumb :pages="pages" title="config. subdivisões" :menu="menu"/>

    <div class="container-fluid qsuite-c-fluid-0">
        <div class="col-12">
            <div class="row">
                <div class="col-12 col-md-12 col-lg-6">
                    <div class="card">
                        <div class="card-body pt-4">
                            <form class="px-2 needs-validation" novalidate @submit.prevent="save">
                                <div class="form-body">
                                    <div class="row">
                                        <div class="col-12 col-md-8">
                                            <div class="form-group">
                                                <label>Nome</label>
                                                <input type="text" v-model="subdivision.name" class="form-control" :class="{'is-invalid': errors.has('name')}" name="name" v-validate="'required'">
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label>ORDEM</label>
                                                <b-form-select class="custom-select mr-sm-2" :options="orders" v-model="subdivision.order" name="order" v-validate="'required'" :class="{'is-invalid': errors.has('order')}">
                                                </b-form-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-actions">
                                    <div class="text-right">
                                        <button type="submit" class="btn btn-info">{{ id ? 'Salvar' : 'Cadastrar' }}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-12 col-lg-6">
                    <div class="col-12">
                        <div class="form-group d-flex">
                            <input type="search" v-model="search" class="form-control campo-procurar" placeholder="Procurar">
                            <button type="button" @click="goSearch" class="btn btn-info">Buscar</button>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body pt-4">
                            <form class="needs-validation" novalidate>
                                <div class="form-body">
                                    <table class="table no-wrap v-middle mb-0">
                                        <thead>
                                            <tr class="border-0">
                                                <th class="border-0 text-muted pt-0 px-2 text-center"><span>Cadastrados</span></th>
                                                <th class="border-0 font-14 font-weight-medium text-muted"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in subdivisions" :key="item.id">
                                                <td class="font-weight-medium text-dark border-top-0 px-2">
                                                    <input type="text" class="form-control" placeholder="" v-model="subdivisions[index].name" disabled>
                                                </td>
                                                <td class="border-top-0 text-muted px-0 py-0 font-14">
                                                    <select class="custom-select mr-sm-2" v-model="subdivisions[index].is_active" disabled>
                                                        <option :value="1">Ativa</option>
                                                        <option :value="0">Inativa</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <button type="button" @click="edit(index)" class="btn btn-info"><i class="fa fa-edit"></i></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import SubdivisionService from "@/services/resources/SubdivisionService";
const service = SubdivisionService.build();

export default {
  data() {
    return {
      subdivision: {
          name: null,
          is_active: true,
          order: null
      },
      id: null,
      subdivisions: [],
      orders: [{text: 'Selecione', value: null}],
      search: '',
      pages:[
            {
                name: 'dashboard',
                to: 'Dashboard'
            }
       ],
       menu:[
            {
                name: 'Subdivisão',
                to: 'ConfigSubdivision'
            }
       ]
    };
  },
  methods: {
    goSearch(){
        if(this.search.length == 0)
            this.fetchSubdivisions();

        this.subdivisions = this.subdivisions.filter(elem => {
            return elem.name.toLowerCase().includes(this.search.toLowerCase());
        });
    },
    edit(index){
        this.id = this.subdivisions[index].id;
        this.subdivision = {
            name: this.subdivisions[index].name,
            is_active: this.subdivisions[index].is_active,
            order: this.subdivisions[index].order
        }
    },
    save(){
      this.$validator.validateAll().then((result) => {
        if (result) {
            let subdivision = Object.assign({}, this.subdivision)

            if(this.id){

                subdivision.id = this.id;
                service
                .update(subdivision)
                .then(resp => {
                    this.$bvToast.toast('Sua configuração foi atualizada com sucesso!', {
                        title: 'Configuração atualizada',
                        autoHideDelay: 5000,
                        type: 'success'
                    })
                    this.$validator.reset();
                    this.fetchSubdivisions();
                })
                .catch(err => {
                    console.log(err)
                })

            }else{
                service
                .create(subdivision)
                .then(resp => {
                    this.$bvToast.toast('Sua configuração foi criada com sucesso!', {
                        title: 'Configuração criada',
                        autoHideDelay: 5000,
                        type: 'success'
                    })
                    this.$validator.reset();
                    this.fetchSubdivisions();
                })
                .catch(err => {
                    console.log(err)
                })
            }
        }
      });
    },
    fetchSubdivisions(){

        service
          .search()
            .then(resp => {
              this.subdivisions = resp;
            })
            .catch(err => {
              console.log(err)
            })
    }

  },
  mounted() {
      this.fetchSubdivisions();
      this.orders = this.orders.concat(new Array(100).fill().map((e,j) => {
          return {value: j + 1, text: j + 1}
      }));
  },
};
</script>
<style scoped lang="scss">
.bg{
  background: #fff;
  padding: 10px;
  border: 1px #ddd solid;
  border-radius: 5px;
}
</style>